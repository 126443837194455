



























































































































































import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import {
  Device as DeviceService,
  Location as LocationService,
} from "@/services/SOLO";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { Device as DeviceModel, Location as LocationModel } from "@/models";
import KiosksFormModal from "./components/KiosksFormModal.vue";
import { translations, notificationAlerts, eventHandler } from "@/mixins";
import moment from "moment";
import Translations from "./components/Translations.vue";
import {mapGetters} from "vuex";
import { Customer } from '@/services/SOLO';
import { Autocomplete, Form, Select, Option } from "element-ui";
import { Connect as ConnectService } from "@/services/SOLO";

@Component({
  components: {
    KiosksItemsTable: () => import("./components/KiosksItemsTable.vue"),
    Events: () => import("./components/Events.vue"),
    LoadingPanel: () => import("@/components/LoadingPanel.vue"),
    KiosksFormModal: () => import("./components/KiosksFormModal.vue"),
    RouteBreadCrumb: () => import("@/components/Breadcrumb/RouteBreadcrumb"),
    [Form.name]: Form,
    [Select.name]: Select,
    [Option.name]: Option,
    [Autocomplete.name]: Autocomplete,
  },
  computed: {
    ...mapGetters({
      getLocale: "app/getLocale",
      getSubscription: 'account/getSubscription',
      getConceptSettings: 'account/getConceptSettings',
      getUserClient: 'account/getUserClient',

    }),
  },
  mixins: [translations, notificationAlerts, eventHandler],
})
export default class Kiosks extends Vue {
  confirm!: Function
  isKioskErrorModal: boolean = false;
  getUserClient!: any
  getLocale!: any;
  getSubscription!: any;
  getConceptSettings!: any;
  translate!: Function;
  systemErrorNotification!: Function;
  successNotification!: Function;
  itemLimit: Number = 100;
  loading: Boolean = false;
  devices: Array<DeviceModel> = [];
  locations: Array<LocationModel> = [];
  locationsLoaded: Boolean = false;
  activeKiosk: any = 0;
  kioskCount: any;
  availableKiosk: any = 0;
  customers: any = [];
  customerMeta: any = {}
  $notify: any;
  $refs!: {
    kiosksFormModal: KiosksFormModal;
  };
  locPage: any = 1;
  page: number = 1;
  isOpen: boolean = false;
  saving: boolean = false;
  form: any = {
    "first-name": '',
    "last-name": '',
    mobile: '',
  };
  tab: any = 1

   mounted() {
    this.getDevices(true);
    this.getLocations(this.locPage);
    // this.getCustomers(this.page)


    if (this.getSubscription && this.getSubscription.attributes['paid-features']) {
      for( let i in this.getSubscription.attributes['paid-features']) {
        if (this.getSubscription.attributes['paid-features'][i]['feature'] == 'kiosk') {
          this.availableKiosk = this.getSubscription.attributes['paid-features'][i]['quantity'];

        }
      }
    }
  }


  openKioskErrorModal(e: any) {
    alert("check");
    this.isKioskErrorModal = true;
  }
  newDummyCustomer() {
    this.isOpen = true;
  }

  closeModal() {
    this.isOpen = false;
  }

  get dropDownText(): string {
    if (this.tab === 1) {
      return this.translate('Kiosk')
    } else {
      return this.translate('Events')
    }                   
  }

  resetForm() {
    this.form["first-name"] = '';
    this.form["last-name"] = '';
    this.form.mobile = '';
  }
  public get getClientUserLabel() {
    return this.getUserClient?.attributes?.label;
  }

  async onSave() {
    this.saving = true;

    try {
      let { data } = await ConnectService.createCustomer({
        ...this.form,
        "account-type": 'guest',
        'username': this.form.mobile,
      });

      this.$notify({
        title: `CUSTOMER CREATED`,
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: `Customer data was created successfully!`,
        type: "success"
      } as any);

      this.closeModal();
      this.resetForm();
    } catch (e) {
      console.log(e);
      /* @ts-ignore */
      Object.keys(e.response.data.messages).map((key: any) => {
        this.$notify({
          title: `CUSTOMER ERROR`,
          verticalAlign: "bottom",
          horizontalAlign: "left",
          /* @ts-ignore */
          message: e.response.data.messages[key][0],
          type: "danger"
        } as any);
      })
    } finally {
      this.saving = false;
    }
  }

   async resetAlarmFunc(item: DeviceModel) {
    console.log("check device data", item);
    let payload = {
          'reset-alarm': true,
           '_method': 'PATCH'
        }
     try {
      const response = await DeviceService.resetAlarm(item.id, payload);
      this.getDevices(true);
      this.successNotification(
      "DEVICE DETAILS",
      "Alarm successfully reset!"
    );

    } catch (error) {
      this.loading = false;
      this.systemErrorNotification();
    }
  }

 async loadNewKiosks() {
    const response = await DeviceService.refreshMdm();

    this.getDevices(false);
    this.successNotification(
      "NEW KIOSKS LOADED",
      "New kiosks has been loaded!"
    );
  }

  onSuccessfulFormSubmit() {
    this.getDevices(false);
  }

  private createItem() {
    this.$refs.kiosksFormModal.open = true;
  }

  private async getItemMdm(item: DeviceModel) {
    let response = await DeviceService.getMdmDeviceDetails(item.id);

    this.getDevices(false);
    this.successNotification(
      "DEVICE DETAILS LOADED",
      "Device details has been loaded!"
    );
  }

  get deleteMsgBoxText(): String {
    return this.translate("Are you sure you want to deactivate this kiosk?");
  }

  private  deactivate(e: any) {

    const model = {
      available: 0
    }

    this.confirm(this.$bvModal, this.deleteMsgBoxText)
      .then((value: boolean) => {
        if(value) {
           DeviceService.update(e.id, model)
            .then(response => {
              this.successNotification(
              "NOTIFICATION",
              "Device successfully deactivated!"
            );
            })
        }
      })
  }

  private editItem(item: DeviceModel) {
    console.log('check kiosk',item);
    this.$refs.kiosksFormModal.model = {
      id: item.id.toString(),
      "location-id": item.attributes["location-id"]?.toString(),
      "customer-id": item.attributes["customer-id"]?.toString(),
      label: item.attributes.label,
      uuid: item.attributes['device-uuid'],
      "ip-address": item.attributes["ip-address"],
      "terminal-id": item.attributes["terminal-id"],
      "is-settings-password-protected": item?.attributes['is-settings-password-protected'],
      "payment-gateway": item.attributes["payment-gateway"],
      "type": item.attributes["type"],
      "port": item.attributes["port"],
      "printer-settings": {
        'printer-manufacturer': item.attributes["printer-settings"]?.["printer-manufacturer"],
        'receipt-mode': item.attributes["printer-settings"]?.["receipt-mode"],
        'ip-address': item.attributes["printer-settings"]?.["ip-address"],
        'printer-name': item.attributes["printer-settings"]?.["printer-name"],
      },
      'payment-settings': {
        'terminal-poiid': item.attributes["payment-settings"]?.["terminal-poiid"],
        'key-version': item.attributes["payment-settings"]?.["key-version"],
        'key-identification': item.attributes["payment-settings"]?.["key-identification"],
        'key-passphrase': item.attributes["payment-settings"]?.["key-passphrase"],
      }




    };
    this.$refs.kiosksFormModal.open = true;
  }

  onModalClose() {
    // do not perform on first load of KiosksFormModal component
    if (this.$refs.kiosksFormModal) {
      // this.$refs.kiosksFormModal.model = { ...kioskDefaultValue };
    }
  }

  async getDevices(showLoading: boolean = true) {
    if (showLoading) {
      this.loading = true;

    }

    try {
      const response = await DeviceService.all();
      this.devices = response.data.data;
      console.log("devices", response.data);
      this.loading = false;
      this.kioskCount = response.data.data.length;

      response?.data?.data.forEach((item: any) => {
        if (item.attributes.status == 'active') {
          this.activeKiosk += 1;
        }
      });
    } catch (error) {
      this.loading = false;
      this.systemErrorNotification();
    }
  }

  async getLocations(page: any) {
    let lang = 'en-us'

    try {
      const response = await LocationService.fetchLocationsActive(lang, page);

      if (response?.data?.data && Array.isArray(response?.data?.data)) {
            this.locations = [...this.locations, ...response?.data?.data];
            this.locations = this.locations.sort((a: any, b: any) => (a.attributes.name > b.attributes.name) ? 1 : -1)

          }
          if (
              response.data.meta.pagination.current_page <
              response.data.meta.pagination.total_pages
          ) {
            this.locPage = response.data.meta.pagination.current_page + 1;

            this.getLocations(response.data.meta.pagination.current_page + 1);
          }
          this.$forceUpdate();

    } catch (error) {
      this.systemErrorNotification();
    }
  }

}
